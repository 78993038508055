<template>
	<section
		class="c-global-basket" ref="root"
		aria-live="polite"
	>
		<div class="c-global-basket__cover" />

		<template v-if="isUserLoggedIn && showBasketSkeleton">
			<global-basket-skeleton></global-basket-skeleton>
		</template>

		<div v-else
			class="c-global-basket__wrapper"
			:class="numberOfItems === 0 ? 'empty' : ''"
			ref="root"
		>
			<div>
				<DsText html-element="h1" text-type="heading-3xl" class="c-global-basket__heading">{{ labels.headline }}</DsText>
				<DsText html-element="span" class="c-global-basket__items">{{ numberOfItems }} {{ itemsLabel }}</DsText>
			</div>

			<template v-if="numberOfItems === 0">
				<basket-empty-state
					:labels="labels"
					:empty-basket-url="emptyBasketUrl" />
			</template>

			<template v-else>
				<notification
					:message="basket.informationMessages?.lineItemSpecificInformationMessage.message"
					:iconName="basket.informationMessages?.lineItemSpecificInformationMessage.iconName"
					v-if="basket.informationMessages?.lineItemSpecificInformationMessage.showMessage" />

				<ul class="c-global-basket__line-items">
					<basket-line-item
						v-for="item in outOfPocketLineItems"
						:key="item.code"
						:item="item"
						:labels="labels"
						:min-quantity="minQuantity"
						:max-quantity="maxQuantity"
						:maxTotalQuantityOfReimbursableItemsAllowed="maxTotalQuantityOfReimbursableItemsAllowed"
						:maxQuantityOfReimburseableItemsReached="maxQuantityOfReimburseableItemsReached"
						:showPricesForReimbursableItems="basket.showPricesForReimbursable"
						:marketSpecificTax="basket.marketSpecificTax"
						@updateQuantity="setBasketItemQuantity({
							code: item.code,
							type: 'outOfPocket',
							quantity: $event,
						})"
					></basket-line-item>

					<DsAlert
						v-if="maxQuantityOfReimburseableItemsReached"
						aria-live="polite"
						appearance="highlight"
						:text="labels.maxReimbursableItemsQuantityReachedMessage"
						class="max-quantity-alert"
					/>

					<basket-line-item
						v-for="item in complimentaryLineItems"
						:key="item.code"
						:item="item"
						:labels="labels"
						:min-quantity="minQuantity"
						:max-quantity="item.maxComplimentaryQuantity "
						:quantity-label="labels.quantityLabel"
						:is-complimentary="true"
						:hide-quantity="basket.hideComplimentaryItemsQuantity"
						:marketSpecificTax="basket.marketSpecificTax"
						:accessibilityLabels="labels.accessibility"
						@updateQuantity="setBasketItemQuantity({
							code: item.code,
							type: 'complimentary',
							quantity: $event,
						})"
					></basket-line-item>
					<basket-line-item
						v-for="item in sampleLineItems"
						:key="item.code"
						:item="item"
						:labels="labels"
						:min-quantity="minQuantity"
						:max-quantity="maxQuantity"
						:is-sample="true"
					></basket-line-item>
				</ul>
				<discount-code
					:labels="labels"
					:isCouponApplied="basket.isCouponApplied"
					v-if="basket.isCouponAvailable" />

				<div>
					<notification
						:message="labels.payLaterNotificationText"
						v-if="showPayFastBlock"
						data-testid="payFastMessage" />

					<notification
						:message="basket.hiddenComplimentaryItemsQuantityMessage"
						v-if="basket.hideComplimentaryItemsQuantity && complimentaryLineItems.length"
						data-testid="hideComplimentaryItemsQuantity"
						iconName="information-circle"
					/>

					<template v-if="e1TaxCalculate.isNeedE1TaxCalculate">
						<notification
							v-if="e1TaxCalculate.taxParams && e1TaxCalculate.taxParams.isPostCodeUnknown !== null"
							:message="e1TaxCalculate.taxParams.isPostCodeUnknown ? labels.notAdjustedVatMessage : labels.adjustedVatMessage"
							:iconName="!e1TaxCalculate.taxParams.isPostCodeUnknown ? 'checkmark' : 'information-circle'"
							data-testid="vatMessage" />
					</template>
					<template v-else>
						<notification
							:message="basket.isTaxValidated ? labels.adjustedVatMessage : labels.notAdjustedVatMessage"
							:iconName="!basket.isTaxValidated ? 'checkmark' : 'information-circle'"
							v-if="basket.marketSpecificTax"
							data-testid="vatMessage" />
					</template>


					<notification
						:message="basket?.informationMessages?.deliveryFeeInformationMessage.message"
						:iconName="basket?.informationMessages?.deliveryFeeInformationMessage.iconName"
						v-if="basket?.informationMessages?.deliveryFeeInformationMessage.showMessage" />

				</div>

				<DsButtonGroup desktop-alignment="start">
					<DsButton
						v-if="labels.browseMoreText"
						data-testid="browseMoreProducts"
						class="c-global-basket__browse-more"
						@click="handleBrowseMoreButtonClick"
					>
						{{ labels.browseMoreText }}
					</DsButton>
				</DsButtonGroup>

				<upsell-section
					:labels="labels.NonRegularProducts"
					v-if="anyOfMyProductsInBasket"
				/>
			</template>
		</div>
	</section>
</template>
<script>
	import { mapGetters, mapMutations, mapActions } from 'vuex';
	import DiscountCode from './discountCode.vue';
	import notification from 'shared/components/basket/notification.vue';
	import UpsellSection from './upsellSection.vue';
	import BasketEmptyState from './basketEmptyState.vue';
	import BasketLineItem from 'shared/components/basket/basketLineItem.vue';
	import GlobalBasketSkeleton from './skeletons/globalBasketSkeleton.vue';
	import { DsButton, DsButtonGroup, DsText, DsAlert } from '@coloplast/design-system';
	import { hideChat, showChat } from 'shared/tools/chat';

	import "@coloplast/design-system/dist/components/alert/ds-alert.css";

	export default {
		name: 'global-basket',
		components: {
			notification,
			DiscountCode,
			UpsellSection,
			BasketEmptyState,
			BasketLineItem,
			GlobalBasketSkeleton,
			DsButton,
			DsButtonGroup,
			DsText,
			DsAlert,
		},
		props: {
			labels: {
				type: Object,
				required: true,
			},
			minQuantity: {
				type: Number,
				default: 1,
			},
			maxQuantity: {
				type: Number,
				default: 99,
			},
			browseMoreUrl: {
				type: String,
				default: null,
			},
			emptyBasketUrl: {
				type: String,
				default: null,
			},
			maxTotalQuantityOfReimbursableItemsAllowed: {
				type: Number,
				default: 0,
			},
			showPricesForReimbursableItems: {
				type: Boolean,
				default: false,
			},
		},
		computed: {
			...mapGetters({
				basket: 'getBasket',
				anyOfMyProductsInBasket: 'getAnyOfMyProductsInBasket',
				showBasketSkeleton: 'getShowBasketSkeleton',
				isUserLoggedIn: 'getIsUserLoggedIn',
				e1TaxCalculate: "getE1TaxCalculate",
				totalQuantityOfReimbursableItems: 'getTotalQuantityOfReimbursableItems',
				globalSettings: 'getGlobalSettings',
			}),
			numberOfItems() {
				return this.outOfPocketLineItems.length + this.sampleLineItems.length + this.complimentaryLineItems.length;
			},
			outOfPocketLineItems() {
				const allItems = this.basket?.paid || [];
				return allItems.filter(item => !item.isComplimentary);
			},
			complimentaryLineItems() {
				const allItems = this.basket?.paid || [];
				return allItems.filter(item => item.isComplimentary);
			},
			sampleLineItems() {
				return this.basket?.samples || [];
			},
			itemsLabel() {
				return this.numberOfItems === 1 ? this.labels.item : this.labels.items;
			},
			showPayFastBlock() {
				return (
					this.labels.payLaterNotificationText &&
					!!this.outOfPocketLineItems.length
				);
			},
			basketIsEmpty() {
				return Object.keys(this.basket).length === 0;
			},
			maxQuantityOfReimburseableItemsReached() {
				return !!this.maxTotalQuantityOfReimbursableItemsAllowed && this.totalQuantityOfReimbursableItems >= this.maxTotalQuantityOfReimbursableItemsAllowed;
			},
		},
		methods: {
			...mapMutations([
				'setBasketItemQuantity',
			]),
			...mapActions([
				'showNavigateToGatedSubSiteModal',
			]),
			handleBrowseMoreButtonClick() {
				if (this.globalSettings.NavigateToGatedSubSiteEnabled) {
					this.showNavigateToGatedSubSiteModal();
				} else if (!this.browseMoreUrl) {
					this.$emit('close');
				} else {
					window.location.assign(this.browseMoreUrl);
				}
			},
		},
		mounted() {
			hideChat();
		},
		unmounted() {
			showChat();
		}
	};
</script>
